<template>
  <section class="interiors">
    <div class="olegdiv">
      <img src="@/assets/oleg/1.webp" alt="" class="photoOlega">
      <div class="textOleg">
        <h1 class="h1">Подивіться приклади реалізованих нами інтерʼєрів</h1>
        <p class="p1">Супровід робіт директором компанії на всіх етапах - від розробки дизайн-проекту, виконання робіт до вручення ключів.</p>
      </div>
    </div>
    <div ref="slick" class="interiorsView">
      <div v-for="(pic, index) in picInteriors" :key="index" @click="openFullScreen(index)">
        <img :src="getImgUrl(pic.srcImg)" alt="">
      </div>

    </div>
    <h2>Створюємо зручний та стильний простір. <span style="background: #ED7C0E; padding: 4px; line-height: 28px;">Оплата по факту виконання робіт,</span> фіксуємо вартість робіт за 1 кв.м.</h2>
    <p class="p2">Офіційний договір та 100% виконання зобовʼязань у гарантійному випадку</p>
    <div v-if="isFullScreen" class="fullScreenOverlay" @click="closeFullScreen">
      <div class="fullScreenImage">
        <img :src="getImgUrl(fullScreenImage.srcImg)" alt="">
        <button class="closeButton" @click="closeFullScreen">Close</button>
      </div>
    </div>

    <div class="estimate">
      <p>Завантажити орієнтовний кошторис</p>
      <form action="sendEstimate.php" method="POST">
        <div class="numberBox">
        <div class="countryPref">
          +38
        </div>
        <input type="tel" class="phone" placeholder="Введіть номер телефону" name="tel" v-mask="'### ### ## ##'" pattern="[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}" required title="Ви ввели номер некоректно">
      </div>
      <button type="submit" id="submitEstimate">Отримати кошторис</button>
      </form>
      <!-- <img src="@/assets/PhotoEstimate.png" alt=""> -->
    </div>

  </section>
</template>

<script>
import 'slick-carousel/slick/slick.css'; // Import slick carousel CSS
import 'slick-carousel/slick/slick-theme.css'; // Import slick carousel theme CSS
import $ from 'jquery';
import 'slick-carousel';

export default {
  name: 'InteriorExamples',
  data() {
    return {
      picInteriors: [
        {"srcImg": "sliders/dubeshnya/02.webp"},
        {"srcImg": "sliders/dubeshnya/06.webp"},
        {"srcImg": "sliders/dubeshnya/01.webp"},
        {"srcImg": "sliders/dubeshnya/05.webp"},
        {"srcImg": "sliders/jkYaskraviy/01.webp"},
        {"srcImg": "sliders/jkYaskraviy/05.webp"},
        {"srcImg": "sliders/jkYaskraviy/09.webp"},
        {"srcImg": "sliders/jkYaskraviy/06.webp"},
      ],
      isFullScreen: false,
      fullScreenIndex: null
    }
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/' + pic);
    },
    openFullScreen(index) {
      this.isFullScreen = true;
      this.fullScreenImage = this.picInteriors[index]; // Встановлюємо fullScreenImage
      document.body.style.overflow = 'hidden';
    },

    closeFullScreen() {
      this.isFullScreen = false;
      this.fullScreenImage = null; // Очищати обране зображення при закритті
      document.body.style.overflow = '';
    }  
  },
  mounted() {
    $(this.$refs.slick).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  },
}
</script>

<style scoped>

.estimate {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    background: #322929;
    color: white;

  }

  .estimate img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }

  .estimate button {
    width: 300px;
    height: 60px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    border-radius: 2px;
    background: #ED7C0E;
    border: none;
    cursor: pointer;
  }

  .estimate p{
    font-size: 20px;
    font-weight: 500;
  }
  
  .phone {
    width: 300px;
    height: 60px;
    border: 0.5px solid #322929;
    border-radius: 2px;
    padding-left: 10px;
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: 2px solid #322929;
    height: 57px;

  }


  #submitEstimate {
    width: 300px;
  }

  form {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;

  }

  .numberBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    width: 300px;
  }

  .countryPref {
    height: 100%;
    border: 0.5px solid #322929;
    border-bottom-left-radius:  2px;
    border-top-left-radius:  2px;
    border-right: none;
    background: #322929;
    color: white;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    margin-right: -1px;
  }


  @media (max-width: 430px) {
    form {
    justify-content: center;
  }

  .phone, #submitEstimate, .numberBox {
    width: 100%;
  }


}

.interiors {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  margin-top: 100px;
  background: #322929;
  padding: 50px;
  width: 100%;
}

.photoOlega {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 3px white;
}

.olegdiv {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}

.textOleg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 600px;
}

.textOleg h1 {
  font-size: 40px;
  font-weight: 900;
  color: white;
}

.textOleg p {
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.interiorsView {
  display: flex;
  width: 1100px;
}

.interiorsView img {
  cursor: pointer;
  width: 220px;
  height: 350px;
  padding: 4px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  max-width: 700px;
}

.p2 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

@media (max-width: 430px) {

  .interiorsView {
  width: 100%;
  }

.interiorsView img {
  width: 100%;
  height: 550px;
}

  .interiors {
    gap: 20px;
    padding: 40px;
    width: 100%;
  }

  .olegdiv {
    flex-direction: column;
    gap: 20px;
  }

  .textOleg {
    width: 330px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .textOleg h1 {
    font-size: 28px;
    text-transform: uppercase;
    max-width: 330px;
    text-align: center;
  }

  .textOleg p {
    font-size: 15px;
    text-align: center;
  }

}

@media (min-width: 431px) and (max-width: 1024px) {

  .interiorsView {
  width: 100%;
}

.interiorsView img {
  width: 100%;
 
}

  .interiors {
    width: 100%;
  }

  .olegdiv {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .textOleg {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .textOleg h1 {
    text-align: center;
    text-transform: uppercase;
  }

  .textOleg p {
    text-align: center;
  }

}


.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 90%;
  max-height: 90vh;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
}
</style>
